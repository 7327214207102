<!--
 * @Descripttion:
 * @Author: Harley
 * @Date: 2023-11-20 17:40:51
 * @LastEditors: harley
 * @LastEditTime: 2024-04-03 18:56:00
-->
<template>
  <div class="right-plane">
    <SearchLayout />
    <StartPolymerizationPanel />
    <!-- <AboutInstructions /> -->
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.right-plane {
  max-height: 100vh;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
</style>
